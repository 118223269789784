<template>
    <div>

        <v-toolbar dark flat :color="$root.themeColor">
            <v-btn icon @click="$root.fn_goBack">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title style="line-height:20px;padding-top: 6px;">
                {{$root.tripTitle}}
                <!-- <div font-small>Club Trip - Trip Organizer</div> -->
            </v-toolbar-title>
        </v-toolbar>

        <div>

            <v-subheader style="background:#f0f0f0"><strong>Club Trip - Trip Organizer</strong></v-subheader>
            <v-list two-line>
                
                <v-list-item class="list_header" @click="fn_checkCameraPermission" v-if="$root.tripInfo.PermList && ($root.tripInfo.PermList.includes(1) || $root.tripInfo.PermList.includes(27))">
                    <v-list-item-icon>
                        <v-icon>mdi-gift-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Gift</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider inset v-if="$root.tripInfo.PermList && ($root.tripInfo.PermList.includes(1) || $root.tripInfo.PermList.includes(27))"></v-divider>
                <v-list-item class="pl-10" @click="fn_checkCameraPermission" v-if="$root.tripInfo.PermList && ($root.tripInfo.PermList.includes(1) || $root.tripInfo.PermList.includes(27))">
                    <v-list-item-icon>
                        <v-icon>mdi-qrcode-scan</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Open QR Scanner</v-list-item-title>
                        <v-list-item-subtitle>Scan QR code to update gifting details</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider inset v-if="$root.tripInfo.PermList && ($root.tripInfo.PermList.includes(1) || $root.tripInfo.PermList.includes(27))"></v-divider>
                <v-list-item class="pl-10" to="/giftattendees" v-if="$root.tripInfo.PermList && ($root.tripInfo.PermList.includes(1) || $root.tripInfo.PermList.includes(27))">
                    <v-list-item-icon>
                        <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Mark Manually</v-list-item-title>
                        <v-list-item-subtitle>Mark gifts manually</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <!-- <v-divider inset></v-divider>

                <v-list-item @click="fn_changeTrip">
                    <v-list-item-icon>
                        <v-icon>mdi-swap-horizontal</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Change Trip</v-list-item-title>
                        <v-list-item-subtitle>Switch to another trip</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item> -->

                <v-divider inset v-if="$root.tripInfo.PermList && ($root.tripInfo.PermList.includes(1) || $root.tripInfo.PermList.includes(28))"></v-divider>

                <v-list-item @click="fn_openActivityTracking" v-if="$root.tripInfo.PermList && ($root.tripInfo.PermList.includes(1) || $root.tripInfo.PermList.includes(28))">
                    <v-list-item-icon>
                        <v-icon>mdi-calendar-clock</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Activity Tracking</v-list-item-title>
                        <v-list-item-subtitle>Activity Tracking</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider inset v-if="$root.tripInfo.PermList && ($root.tripInfo.PermList.includes(1) || $root.tripInfo.PermList.includes(31))"></v-divider>

                <v-list-item to="/attendees" v-if="$root.tripInfo.PermList && ($root.tripInfo.PermList.includes(1) || $root.tripInfo.PermList.includes(31))">
                    <v-list-item-icon>
                        <v-icon>stars</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Attendees</v-list-item-title>
                        <v-list-item-subtitle>Attendees</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item to="/about">
                    <v-list-item-icon>
                        <v-icon>mdi-information-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>About</v-list-item-title>
                        <v-list-item-subtitle>More information about app</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <!-- <template>
                
                    <v-divider inset></v-divider>

                    <v-list-item @click="fn_testFn">
                        <v-list-item-icon>
                            <v-icon>mdi-information-outline</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>For Testing</v-list-item-title>
                            <v-list-item-subtitle>Testing for development</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    
                </template> -->
                
            </v-list>

            <v-alert prominent text color="info" dense style="position:fixed;bottom:0;width:100%" v-if="updateAvailable">
                <v-subheader class="pa-0 info--text">
                    Updated version available
                    <v-spacer></v-spacer>
                    <v-btn small color="primary" @click="fn_downloadUpdate" v-if="$root.downloadStatus == null"><v-icon left>download</v-icon> Install</v-btn>
                    <v-progress-circular
                        v-else
                        :rotate="-90"
                        :size="36"
                        :width="4"
                        :indeterminate="$root.downloadStatus == 0 ? true : false"
                        :value="$root.downloadStatus"
                        color="primary"
                        >
                        <span style="font-size:x-small" v-if="$root.downloadStatus">{{ $root.downloadStatus }}%</span>
                    </v-progress-circular>

                </v-subheader>
                <!-- <v-layout row wrap class="ma-0">
                    <v-flex xs8 sm8 md8>Updated version available</v-flex>
                    <v-flex xs4 sm4 md4>
                        <v-btn icon @click="fn_downloadUpdate"><v-icon left>download</v-icon> Install</v-btn>
                    </v-flex>
                </v-layout> -->
            </v-alert>

            <!-- <div class="pa-8">
                <v-img width="80" style="margin:auto" src="../assets/qr_code.svg"></v-img>

                <v-btn class="mt-4" :disabled="loading" outlined @click="fn_checkCameraPermission">
                    {{loading ? 'Updating details...' : 'Open QR Scanner'}}
                </v-btn>
                <div font-small class="mt-4">Scan QR code to update gifting details</div>
            </div> -->
        </div>

        <v-dialog v-model="dialog" max-width="320px" transition="dialog-transition">
            <v-card>
                <v-card-title primary-title>
                    Scanned Code
                </v-card-title>
                <v-card-text>
                    {{code}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog=false">Okay</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="activityTrackingDialog" scrollable transition="dialog-bottom-transition" fullscreen persistent>
            <v-card>
                <v-card-title :style="{ backgroundColor: $root.themeColor}" :color="$root.themeColor"  class="title pb-4 pl-4 white--text" style="flex-wrap:nowrap;word-break: break-word;">
                    <v-btn :color="$root.themeColor" icon class="mr-4 white--text" @click="activityTrackingDialog=false">
                        <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
                    </v-btn>
                    Activity Tracking
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="scrollable-content form_dialog_text pt-4 platinumclub_custom_formatting">
                    <template v-if="loading">
                        <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
                    </template>
                    <template v-else>
                        <div>
                            <div class="subtitle-1 pt-2">Select Activity<span class="red--text">*</span></div>
                            <v-radio-group class="mt-2 dateselect_btn" row v-model="itemObj.ActivityID" hide-details>
                                <v-radio @change="fn_dateChange" class="mb-4" v-for="(item, index) in activityList" :key="index" :label="fn_getActivityTitle(item)" :value="item.ActivityID"></v-radio>
                            </v-radio-group>
                        </div>
                        <div v-if="itemObj.ActivityID != null">
                            <div id="activityblock" class="subtitle-1 pt-2">Select Date<span class="red--text">*</span></div>
                            <v-radio-group class="mt-2 dateselect_btn" v-model="itemObj.ActivityBlockID" hide-details>
                                <template v-for="(item, index) in ActivityBlocksList">
                                    <div :key="index" class="mb-4">
                                        <v-radio class="mb-1" :label="formattedLabel(item)" :value="item.ActivityBlockID"></v-radio>
                                        <div style="padding-left: 34px;">{{ item.Timing != null ? item.Timing : '' }}</div>
                                    </div>
                                </template>
                            </v-radio-group>
                        </div>
                    </template>
                    
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions v-if="!loading">
                    <v-spacer></v-spacer>
                    <v-btn :disabled="itemObj.ActivityBlockID == null || itemObj.ActivityID == null" block :color="$root.themeColor" tile class="btn-white mt-1 pl-3 pr-3 white--text confirmation_btn" @click="fn_next">Next</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="activityTrackingAttendeeDialog" scrollable transition="dialog-bottom-transition" fullscreen persistent>
            <v-card>
                <v-card-title :style="{ backgroundColor: $root.themeColor}" :color="$root.themeColor"  class="title pb-4 pl-4 white--text" style="flex-wrap:nowrap;word-break: break-word;">
                    <v-btn :color="$root.themeColor" icon class="mr-4 white--text" @click="fn_closeAttendeeDialog">
                        <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
                    </v-btn>
                    Mark Attendees
                    <v-spacer></v-spacer>
                    <v-btn tile outlined small class="white--text btn-white mt-1 pl-3 pr-3 confirmation_btn" @click="fn_getActivityBlockView">Refresh</v-btn>
                    <!-- <v-spacer></v-spacer>
                    <v-btn v-if="itemObj.ActivityBlockID != null && activityDetailObj.ActivityNotes == null" outlined :color="$root.themeColor" tile class="btn-white mt-1 pl-3 pr-3 white--text confirmation_btn" @click="fn_closeAttendeeDialog">Add Note</v-btn> -->
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="scrollable-content form_dialog_text pt-4 platinumclub_custom_formatting">
                    <template v-if="loading">
                        <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
                    </template>
                    <template v-else>
                        <div>
                            <div style="display: flex;" class="mt-2">
                                <p style="min-width: 93px;" class="mb-2"><b>Activity Name: </b></p>
                                <p class="mb-2 pl-1">{{ ActivityName }}</p>
                            </div>
                            <div style="display: flex;">
                                <p class="mb-2"><b>Activity Date/Time: </b></p>
                                <p class="mb-2 pl-1">{{ formattedLabel(ActivityBlockDetail) }} <br>{{ ActivityBlockDetail.Timing != null ? ActivityBlockDetail.Timing : '' }}</p>
                            </div>
                            
                        </div>

                        <div v-if="itemObj.ActivityBlockID != null">
                            <div class="subtitle-1 pt-2"><b>Total Attendees: </b>{{TotalAttendeesCount}} / <b>Checked Attendees: </b> {{TotalAttendedCount}}</div>
                            <template v-if="attendeeloading">
                                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
                                <!-- <v-progress-circular indeterminate :color="$root.themeColor"></v-progress-circular> -->
                            </template>
                            <div v-else>
                                <div v-if="activityDetailObj.Attendees && activityDetailObj.Attendees.length>0">
                                    <div class="pt-4">
                                        <ol>
                                            <li class="mb-3 attendee_checkbox" v-for="attendee in activityDetailObj.Attendees" :key="attendee.AttendeeID">
                                                
                                                    <v-checkbox :class="(attendee.PersonalConsiderations && attendee.PersonalConsiderations.DietaryRequirements && attendee.PersonalConsiderations.DietaryRequirements.length > 0) ? 'pb-0' : 'pb-2'" v-if="attendee.AttendeeTypeEnum == 3" class="mt-0 pt-0" v-model="attendee.IsAttendanceMarked" :label="fn_getGuestAge(attendee)" hide-details @change="fn_markAttendenceType(attendee.AttendeeID, attendee.IsAttendanceMarked, attendee.AttendeeName)"></v-checkbox>
                                                    <!-- <div v-if="attendee.PersonalConsiderations && attendee.PersonalConsiderations.DietaryRequirements">( <span v-for="(dietry, index) in attendee.PersonalConsiderations.DietaryRequirements" :key="index">{{ dietry }}</span> )</div> -->
                                                    <v-checkbox :class="(attendee.PersonalConsiderations && attendee.PersonalConsiderations.DietaryRequirements && attendee.PersonalConsiderations.DietaryRequirements.length > 0) ? 'pb-0' : 'pb-2'" v-else class="mt-0 pt-0" v-model="attendee.IsAttendanceMarked" :label="attendee.AttendeeName" hide-details @change="fn_markAttendenceType(attendee.AttendeeID, attendee.IsAttendanceMarked, attendee.AttendeeName)"></v-checkbox>
                                                    <div class="pl-7 pt-1" style="font-size: 11pt;" v-if="attendee.PersonalConsiderations && attendee.PersonalConsiderations.DietaryRequirements && attendee.PersonalConsiderations.DietaryRequirements.length>0">
                                                        (<span v-for="(dietry, index) in attendee.PersonalConsiderations.DietaryRequirements" :key="index">{{ getDietaryRequirement(dietry, attendee.PersonalConsiderations) }}<span v-if="index < attendee.PersonalConsiderations.DietaryRequirements.length - 1">, </span></span>)
                                                    </div>
                                                    <div class="pl-7 pt-1" style="font-size: 11pt;" v-if="attendee.PersonalConsiderations && showDietaryRequirement(attendee.PersonalConsiderations)">
                                                        (<span v-for="(dietryquestion, reqIndex) in formatDietaryRequirements(attendee.PersonalConsiderations)" :key="reqIndex">{{dietryquestion}}<span v-if="reqIndex < formatDietaryRequirements(attendee.PersonalConsiderations).length - 1">, </span></span>)
                                                    </div>
                                                    <!-- <v-checkbox v-else :key="attendee.AttendeeID" disabled class="mt-0 pt-0 pb-2" :label="attendee.AttendeeName" :input-value="true" hide-details></v-checkbox> -->
                                                
                                                <ol class="pt-3" v-if="attendee.SubAttendees && attendee.SubAttendees.length>0">
                                                    <li type="a" class="mb-3" v-for="subattendee in attendee.SubAttendees" :key="subattendee.AttendeeID">
                                                        <template>
                                                            <v-checkbox :class="(subattendee.PersonalConsiderations && subattendee.PersonalConsiderations.DietaryRequirements && subattendee.PersonalConsiderations.DietaryRequirements.length > 0) ? 'pb-0' : 'pb-2'" v-if="subattendee.AttendeeTypeEnum == 3" class="mt-0 pt-0" v-model="subattendee.IsAttendanceMarked" :label="fn_getGuestAge(subattendee)" hide-details @change="fn_markAttendenceType(subattendee.AttendeeID, subattendee.IsAttendanceMarked, subattendee.AttendeeName)"></v-checkbox>
                                                            <v-checkbox :class="(subattendee.PersonalConsiderations && subattendee.PersonalConsiderations.DietaryRequirements && subattendee.PersonalConsiderations.DietaryRequirements.length > 0) ? 'pb-0' : 'pb-2'" v-else class="mt-0 pt-0" v-model="subattendee.IsAttendanceMarked" :label="subattendee.AttendeeName" hide-details @change="fn_markAttendenceType(subattendee.AttendeeID, subattendee.IsAttendanceMarked, subattendee.AttendeeName)"></v-checkbox>
                                                            <div class="pl-7 pt-1" style="font-size: 11pt;" v-if="subattendee.PersonalConsiderations && subattendee.PersonalConsiderations.DietaryRequirements && subattendee.PersonalConsiderations.DietaryRequirements.length>0">
                                                                (<span v-for="(dietry, index) in subattendee.PersonalConsiderations.DietaryRequirements" :key="index">{{getDietaryRequirement(dietry, subattendee.PersonalConsiderations)}}<span v-if="index < subattendee.PersonalConsiderations.DietaryRequirements.length - 1">, </span></span>)
                                                            </div>
                                                            <div class="pl-7 pt-1" style="font-size: 11pt;" v-if="subattendee.PersonalConsiderations && showDietaryRequirement(subattendee.PersonalConsiderations)">
                                                                (<span v-for="(dietryquestion, subreqIndex) in formatDietaryRequirements(subattendee.PersonalConsiderations)" :key="subreqIndex">{{dietryquestion}}<span v-if="subreqIndex < formatDietaryRequirements(subattendee.PersonalConsiderations).length - 1">, </span></span>)
                                                            </div>
                                                           
                                                            <!-- <v-checkbox v-else :key="subattendee.AttendeeID" disabled class="mt-0 pt-0 pb-2" :label="subattendee.AttendeeName" :input-value="true" hide-details></v-checkbox> -->
                                                        </template>
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                        
                                    </div>
                                </div>
                                <div v-else>
                                    <p>No attendees found!</p>
                                </div>
                                <div style="position: relative;">
                                    <div v-if="activityDetailObj.ActivityNotes" class="subtitle-1 pt-2">Notes</div>
                                    <!-- <v-btn style="position: absolute; right: 0px; top: 7px;" :color="$root.themeColor" tile outlined class="btn-white mt-1 pl-3 pr-3 confirmation_btn" @click="fn_editNote"><v-icon>edit</v-icon> Edit Notes</v-btn> -->
                                    <div>
                                        <!-- <a @click="fn_openAddNoteDialog">Add Notes</a> -->
                                        <v-btn v-if="activityDetailObj.ActivityNotes" style="position: absolute; right: 0px; top: 7px;" :color="$root.themeColor" tile outlined small plain class="btn-white mt-1 pl-3 pr-3 confirmation_btn" @click="fn_editNote"><v-icon>edit</v-icon> Edit Notes</v-btn>
                                    </div>
                                    <div v-if="activityDetailObj.ActivityNotes" class="pt-4 pb-3" style="color:black; font-size: 12pt;;" v-html="activityDetailObj.ActivityNotes"></div>
                                    <v-btn v-if="activityDetailObj.ActivityNotes == null" :color="$root.themeColor" tile outlined small plain class="btn-white mt-4 pl-3 pr-3 confirmation_btn" @click="fn_openAddNoteDialog"><v-icon>add</v-icon> Add Notes</v-btn>
                                </div>
                            </div>
                           
                        </div>
                        <!-- <div v-if="slotDate != null && slotDate != ''">
                            <div class="subtitle-1 pt-2">Select Time<span class="red--text">*</span></div>
                            <v-radio-group row class="mt-2 timeselect_btn" v-model="MobileHeadShotSlotID" hide-details>
                                <div class="mb-2 width_100" v-for="(slottime, index) in groupedSlots" :key="index">
                                    <v-radio :disabled="MobileHeadShotSlotID != item.MobileHeadShotSlotID && item.IsSlotFull == true" :style="{ borderColor: item.MobileHeadShotSlotID == MobileHeadShotSlotID ? $root.themeColor : ''}" class="mb-4" v-for="(item, index2) in slottime.slots" :key="index2" :label="item.SlotTime | moment('hh:mm A')" :value="item.MobileHeadShotSlotID"></v-radio>
                                </div>
                                
                            </v-radio-group>
                        </div> -->

                    </template>
                    
                </v-card-text>
                <!-- <v-divider></v-divider>
                <v-card-actions v-if="!loading">
                    <v-spacer></v-spacer>
                    <v-btn block :color="$root.themeColor" tile class="btn-white mt-1 pl-3 pr-3 white--text confirmation_btn" @click="fn_SaveHeadShotSelection">Add Note</v-btn>
                </v-card-actions> -->
            </v-card>
        </v-dialog>

        <v-dialog v-model="addNotesDialog" scrollable transition="dialog-bottom-transition" fullscreen persistent>
            <v-card>
                <v-card-title :style="{ backgroundColor: $root.themeColor}" :color="$root.themeColor"  class="title pb-4 pl-4 white--text" style="flex-wrap:nowrap;word-break: break-word;">
                    <v-btn :color="$root.themeColor" icon class="mr-4 white--text" @click="fn_closeAddNoteDialog">
                        <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
                    </v-btn>
                    {{activityDetailObj.ActivityNotes ? 'Edit Notes' : 'Add Notes'}}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="scrollable-content form_dialog_text pt-4 platinumclub_custom_formatting">
                    <template v-if="loading">
                        <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
                    </template>
                    <template v-else>
                        <div>
                            <div class="subtitle-1 pt-2">Notes<span class="red--text">*</span></div>
                            <v-textarea v-model="noteText" auto-grow outlined rows="10" row-height="15"></v-textarea>
                            <!-- <quill-editor class="richetxtcustom_style addtext_richtexteditor showoptionsatbottomclosed" style="position: relative;" ref="myQuillEditor" v-model="noteText" :options="editorOptions">
                            </quill-editor> -->
                        </div>
                       
                    </template>
                    
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions v-if="!loading">
                    <v-spacer></v-spacer>
                    <v-btn :disabled="noteText == null || noteText == ''" block :color="$root.themeColor" tile class="btn-white mt-1 pl-3 pr-3 white--text confirmation_btn" @click="fn_addNote">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="ConfimationDialog" persistent>
            <v-card>
                <v-card-title class="text-h6">Confirmation</v-card-title>
                <!-- <v-divider></v-divider> -->
                <v-card-text>Are you sure you want to uncheck <b>{{ AttendeeName }}</b>?</v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :color="$root.themeColor" tile class="btn-white pl-3 pr-3 confirmation_btn white--text" @click="fn_unMarkAttendence">Confirm</v-btn>
                    <v-btn :color="$root.themeColor" outlined tile class="btn-white ml-3 mr-4" @click="fn_closeConfirmationDialog">
                        <v-icon>close</v-icon> Close
                    </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <v-dialog v-model="Loadingdialog" content-class="loading-dialog" persistent max-width="250">
            <v-card class="pa-2" height="75">
                <div class="text-center">
                    <v-progress-circular :size="40" color="primary" indeterminate></v-progress-circular>
                    <div class="loading-msg pl-3">{{LoadingMessage}}</div> 
                </div>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
// import axios from 'axios';
import axios from 'axios';
// import { QuillEditor } from '@vueup/vue-quill';
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
const moment = require('moment');

    export default{
        data(){
            return{
                loading:false,
                attendeeloading: false,
                code:null,
                dialog:false,
                updateAvailable:false,
                confirming:false,
                activityTrackingDialog: false,
                activityList: [],
                AttendeeList: [],
                AttendeeAcceptedList: [],
                slotDate: null,
                error: false,
                itemObj: { ActivityID: null, ActivityBlockID: null },
                activityId: null,
                activityDetailObj: { Attendees: [], ActivityNotes: null },
                addNotesDialog: false,
                editorOptions: {
                    theme: 'snow',
                    modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline'],  // Include the options for bold, italic, and underline
                    ]
                    }
                },
                noteText: null,
                oldNoteText: null,
                activityTrackingAttendeeDialog: false,
                TotalAttendeesCount: 0,
                TotalAttendedCount: 0,
                Loadingdialog: false,
                LoadingMessage: "Saving...",
                ConfimationDialog: false,
                AttendeeName: null,
                AttendeeID: null,
                IsMarked: null
            }
        },
        components: {
            // QuillEditor
        },
        methods:{

            getDietaryRequirement(dietry, item) {
                if (dietry === 'Other') {
                    return 'Other: ' + item.OtherDietaryRequirements;
                } 
                // else if (dietry === 'Allergies') {
                //     return 'Allergies: ' + item.AllergyDetail;
                // }
                // else if (dietry === 'Indian Vegetarian') {
                //     return 'Indian Vegetarian: ' + (item.IndianVegeterian == true ? 'Yes' : 'No');
                // }
                // else if (dietry === 'Kosher') {
                //     return 'Kosher: ' + (item.KosherCertified == true ? 'Yes' : 'No');
                // }
                // else if (dietry === 'Halal') {
                //     return 'Halal: ' + (item.HalalCertified == true ? 'Yes' : 'No');
                // } 
                else {
                    return dietry;
                }
            },

            showDietaryRequirement(item) {
                if (item.DietaryRequirements && item.DietaryRequirements.length>0 && item.HalalCertified) {
                    return true
                }
                else if (item.DietaryRequirements && item.DietaryRequirements.length>0 && item.IndianVegeterian) {
                    return true
                }
                else if (item.DietaryRequirements && item.DietaryRequirements.length>0 && item.KosherCertified) {
                    return true
                }
                else if (item.DietaryRequirements && item.DietaryRequirements.length>0 && item.AllergyDetail) {
                    return true
                }
                else {
                    return false
                }
            },

            fn_testFn: function(){
                this.getData('TestFunction', null, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                        },
                        onError: function(error){
                            console.log(error);
                        }
                    }
                ); 
            },

            fn_checkCameraPermission: function(){
                // this.$root.fn_showToast('test')
                let self = this;
                this.getData('CheckCameraPermission', "CameraPermission", 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.fn_scanQR();
                        },
                        onError: function(error){
                            console.log(error);
                        }
                    }
                ); 
            },

            fn_scanQR: function(){
                let self = this;
                this.getData('OpenScanner', null, 
                    {
                        onSuccess: function(response){
                            console.log(response + "Scanner Success");
                            // self.code = response;
                            self.$router.push({path:'/attendeedetails', query:{'requestId':response}})
                            // self.dialog = true;
                            // self.fn_updateScanDetails(response);
                        },
                        onError: function(error){
                            console.log(error + "Scanner Error");
                            this.$root.fn_showToast("Unable to open scanner");
                        }
                    }
                );
            },

            fn_changeTrip: function(){
                this.$router.push({path:'/trips', query:{'goback':1}})
            },

            fn_checkUpdate: function(){
                let self = this;
                this.getData('CheckForUpdate', null, {
                    onSuccess: function(response){
                        console.log(response);
                        if(JSON.parse(response)){
                            self.updateAvailable = true;
                        }
                        else{
                            self.updateAvailable = false;
                        }
                    },
                    onError: function(error){
                        console.log(error);
                    }
                });
            },

            fn_downloadUpdate: function(){
                this.$root.downloadStatus = 0;
                this.getData('InstallUpdate', null, {
                    onSuccess: function(response){
                        console.log(response);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                });
            },

            fn_closeAdminApp: function(){

            },

            fn_openActivityTracking: function(){
                this.activityTrackingDialog = true;
                this.loading = false;
                this.fn_getActivityList();
                this.itemObj = { ActivityID: null, ActivityBlockID: null };
                this.activityDetailObj = { Attendees: [], ActivityNotes: null };
                this.attendeeloading = false;
                this.AttendeeAcceptedList = []
            },

            formattedLabel(item) {
                return `${moment(item.ActivityBlockDate).format('ddd, MMM Do YYYY')}`;
            },

            formattedTimeLabel(item) {
                return `${moment(item.ActivityBlockDate).format('ddd, MMM Do YYYY')}${item.Timing == null ? '' : ' (' + item.Timing + ')'}`;
            },

            fn_dateChange: function(){
                this.itemObj = { ActivityID: null, ActivityBlockID: null };
                this.activityDetailObj = { Attendees: [], ActivityNotes: null };
                this.attendeeloading = false;
                this.AttendeeAcceptedList = [];
                this.fn_scrollToView();
            },

            fn_getActivityList: function(){
                this.loading = true;
                this.error = false;
                let self = this;

                this.$root.fn_getToken(token=>{
                    if(token == 'error'){
                        this.loading = false;
                        this.error = true;
                        this.$root.fn_showToast('exception');
                    }
                    else{
                        var headersObj = {
                            headers: { 
                                'Authorization': 'bearer '+token,
                            }
                        };
  
                        axios.get(self.$root.baseUrl + '/api/mobileapp/admin/activity/list', headersObj, {timeout:30000})
                        .then(response => {
                            self.activityList =  response.data;
                            // console.log(self.attendeeInfo.ImageUrl);
                            this.loading = false;
                            self.$forceUpdate();
                        }).catch(e => {
                            this.loading = false;
                            this.error = true;
                            let toastMsg = e && e.message ? e.message : 'exception';
                            this.$root.fn_showToast(toastMsg);
                            console.log(e);
                        })
                    }
                })

                
            },

            fn_getActivityBlockView: function(){
                this.AttendeeAcceptedList = []
                this.attendeeloading = true;
                this.loading = true;
                this.error = false;
                let self = this;

                this.$root.fn_getToken(token=>{
                    if(token == 'error'){
                        self.attendeeloading = false;
                        self.error = true;
                        self.$root.fn_showToast('exception');
                    }
                    else{
                        var headersObj = {
                            headers: { 
                                'Authorization': 'bearer '+token,
                            }
                        };
                        var getURL = null;
                        if(this.ActivityType == 2 )
                            getURL = '/api/mobileapp/admin/nonhostedactivity/block/' + self.itemObj.ActivityBlockID + '/view';
                        else
                            getURL = '/api/mobileapp/admin/activity/block/' + self.itemObj.ActivityBlockID + '/view';
                        
                        axios.get(self.$root.baseUrl + getURL, headersObj, {timeout:30000})
                        .then(response => {
                            self.activityDetailObj =  response.data;
                            // console.log(self.attendeeInfo.ImageUrl);
                            self.attendeeloading = false;
                            self.loading = false;
                            self.addNotesDialog = false;
                            self.fn_attendedCount();
                            self.fn_attendeesCount();
                            self.$forceUpdate();
                        }).catch(e => {
                            self.attendeeloading = false;
                            self.error = true;
                            let toastMsg = e && e.message ? e.message : 'exception';
                            self.$root.fn_showToast(toastMsg);
                            console.log(e);
                        })
                    }
                })

                
            },

            fn_markAttendenceType: function(attendeeid, ismarked, attendeename){
                this.AttendeeID = attendeeid;
                this.IsMarked = ismarked;
                this.AttendeeName = attendeename;
                if(ismarked)
                    this.fn_markAttendence(attendeeid, ismarked);
                else
                    this.ConfimationDialog = true;
            },

            fn_closeConfirmationDialog: function(){
                for(var i in this.activityDetailObj.Attendees){
                    if(this.activityDetailObj.Attendees[i].AttendeeID == this.AttendeeID){
                        this.activityDetailObj.Attendees[i].IsAttendanceMarked = true;
                        break;
                    }

                    // Check the SubAttendees, if any
                    if (this.activityDetailObj.Attendees[i].SubAttendees && this.activityDetailObj.Attendees[i].SubAttendees.length > 0) {
                        for (var j in this.activityDetailObj.Attendees[i].SubAttendees) {
                            if (this.activityDetailObj.Attendees[i].SubAttendees[j].AttendeeID == this.AttendeeID) {
                                this.activityDetailObj.Attendees[i].SubAttendees[j].IsAttendanceMarked = true;
                                break;
                            }
                        }
                    }
                }
                this.ConfimationDialog = false;
            },

            fn_markAttendence: function(attendeeid, ismarked){
                this.Loadingdialog = true;
                this.error = false;
                let self = this;

                this.$root.fn_getToken(token=>{
                    if(token == 'error'){
                        self.Loadingdialog = false;
                        self.error = true;
                        self.$root.fn_showToast('exception');
                        self.fn_stopUnmarking(attendeeid, ismarked);
                    }
                    else{
                        var headersObj = {
                            headers: { 
                                'Authorization': 'bearer '+token,
                            }
                        };
  
                        var getUrl = null;
                        if(this.ActivityType == 2 )
                            getUrl = self.$root.baseUrl + '/api/mobileapp/admin/nonhostedactivityblock/' + self.itemObj.ActivityBlockID + '/add/attendee/' + attendeeid + '/attendance';
                        else
                            getUrl = self.$root.baseUrl + '/api/mobileapp/admin/activityblock/' + self.itemObj.ActivityBlockID + '/add/attendee/' + attendeeid + '/attendance';
                        axios.get(getUrl, headersObj, {timeout:30000})
                        .then(response => {
                            // self.activityDetailObj =  response.data;
                            for(var i in self.activityDetailObj.Attendees){
                                if(self.activityDetailObj.Attendees[i].AttendeeID == attendeeid){
                                    self.activityDetailObj.Attendees[i].IsAttendanceMarked = true;
                                    break;
                                }

                                if (self.activityDetailObj.Attendees[i].SubAttendees && self.activityDetailObj.Attendees[i].SubAttendees.length > 0) {
                                    for (var j in self.activityDetailObj.Attendees[i].SubAttendees) {
                                        if (self.activityDetailObj.Attendees[i].SubAttendees[j].AttendeeID == attendeeid) {
                                            self.activityDetailObj.Attendees[i].SubAttendees[j].IsAttendanceMarked = true;
                                            break;
                                        }
                                    }
                                }
                            }
                            self.fn_attendedCount();
                            console.log(response.data);
                            self.Loadingdialog = false;
                            self.$forceUpdate();
                        }).catch(e => {
                            self.fn_stopUnmarking(attendeeid, ismarked);
                            self.Loadingdialog = false;
                            self.error = true;
                            let toastMsg = e && e.message ? e.message : 'exception';
                            self.$root.fn_showToast(toastMsg);
                            console.log(e);
                        })
                    }
                })

                
            },

            fn_unMarkAttendence: function(){
                this.Loadingdialog = true;
                this.error = false;
                let self = this;

                this.$root.fn_getToken(token=>{
                    if(token == 'error'){
                        self.Loadingdialog = false;
                        self.error = true;
                        self.$root.fn_showToast('exception');
                        self.fn_stopUnmarking(self.AttendeeID, self.IsMarked);
                    }
                    else{
                        var headersObj = {
                            headers: { 
                                'Authorization': 'bearer '+token,
                            }
                        };
  
                        var getUrl = null;
                        if(this.ActivityType == 2 )
                            getUrl = self.$root.baseUrl + '/api/mobileapp/admin/nonhostedactivityblock/' + self.itemObj.ActivityBlockID + '/tracker/remove/attendee/' + self.AttendeeID + '/attendance';
                        else
                            getUrl = self.$root.baseUrl + '/api/mobileapp/admin/activityblock/' + self.itemObj.ActivityBlockID + '/tracker/remove/attendee/' + self.AttendeeID + '/attendance';
                        axios.get(getUrl, headersObj, {timeout:30000})
                        .then(response => {
                            // self.activityDetailObj =  response.data;
                            self.ConfimationDialog = false;
                            for(var i in self.activityDetailObj.Attendees){
                                if(self.activityDetailObj.Attendees[i].AttendeeID == self.AttendeeID){
                                    self.activityDetailObj.Attendees[i].IsAttendanceMarked = null;
                                    break;
                                }
                                if (self.activityDetailObj.Attendees[i].SubAttendees && self.activityDetailObj.Attendees[i].SubAttendees.length > 0) {
                                    for (var j in self.activityDetailObj.Attendees[i].SubAttendees) {
                                        if (self.activityDetailObj.Attendees[i].SubAttendees[j].AttendeeID == self.AttendeeID) {
                                            self.activityDetailObj.Attendees[i].SubAttendees[j].IsAttendanceMarked = null;
                                            break;
                                        }
                                    }
                                }
                            }
                            self.fn_attendedCount();
                            console.log(response.data);
                            self.Loadingdialog = false;
                            self.$forceUpdate();
                        }).catch(e => {
                            self.fn_stopUnmarking(self.AttendeeID, self.IsMarked);
                            self.Loadingdialog = false;
                            self.error = true;
                            let toastMsg = e && e.message ? e.message : 'exception';
                            self.$root.fn_showToast(toastMsg);
                            console.log(e);
                        })
                    }
                })

                
            },

            fn_stopUnmarking: function(attendeeid, ismarked){
                for(var i in this.activityDetailObj.Attendees){
                    if(this.activityDetailObj.Attendees[i].AttendeeID == attendeeid){
                        if(ismarked)
                            this.activityDetailObj.Attendees[i].IsAttendanceMarked = null;
                        else
                            this.activityDetailObj.Attendees[i].IsAttendanceMarked = true;
                        break;
                    }

                    // Check the SubAttendees, if any
                    if (this.activityDetailObj.Attendees[i].SubAttendees && this.activityDetailObj.Attendees[i].SubAttendees.length > 0) {
                        for (var j in this.activityDetailObj.Attendees[i].SubAttendees) {
                            if (this.activityDetailObj.Attendees[i].SubAttendees[j].AttendeeID == attendeeid) {
                                if (ismarked)
                                    this.activityDetailObj.Attendees[i].SubAttendees[j].IsAttendanceMarked = null;
                                else
                                    this.activityDetailObj.Attendees[i].SubAttendees[j].IsAttendanceMarked = true;
                                break;
                            }
                        }
                    }
                }
            },

            fn_openAddNoteDialog: function(){
                this.addNotesDialog = true;
                this.noteText = null;
            },

            fn_closeAddNoteDialog: function(){
                this.addNotesDialog = false;
                this.noteText = null;
            },

            fn_addNote: function(){
                this.loading = true;
                this.error = false;
                let self = this;

                this.$root.fn_getToken(token=>{
                    if(token == 'error'){
                        self.loading = false;
                        self.error = true;
                        self.$root.fn_showToast('exception');
                    }
                    else{
                        var headersObj = {
                            headers: { 
                                'Authorization': 'bearer '+token,
                            }
                        };
  
                        var formObj = { ActivityBlockID:  this.itemObj.ActivityBlockID, ActivityNotes: this.noteText };
                        var postURL = null;
                        if(this.ActivityType == 2 )
                            postURL = '/api/mobileapp/admin/nonhostedactivityblock/add/notes';
                        else
                            postURL = '/api/mobileapp/admin/activityblock/add/notes';
                        axios.post(self.$root.baseUrl + postURL, formObj, headersObj, {timeout:30000})
                        .then(response => {
                            // self.activityDetailObj =  response.data;
                            console.log(response.data);
                            self.activityDetailObj.ActivityNotes = JSON.parse(JSON.stringify(self.noteText));
                            self.noteText = null;
                            // self.fn_getActivityBlockView();
                            self.loading = false;
                            self.addNotesDialog = false;
                            self.$forceUpdate();
                        }).catch(e => {
                            self.loading = false;
                            self.error = true;
                            let toastMsg = e && e.message ? e.message : 'exception';
                            self.$root.fn_showToast(toastMsg);
                            console.log(e);
                        })
                    }
                })

                
            },

            fn_editNote: function(){
                this.oldNoteText = JSON.parse(JSON.stringify(this.activityDetailObj.ActivityNotes));
                this.noteText = JSON.parse(JSON.stringify(this.activityDetailObj.ActivityNotes));
                this.addNotesDialog = true;
            },

            fn_next: function(){
                this.activityTrackingAttendeeDialog = true;
                this.fn_getActivityBlockView();
            },

            fn_closeAttendeeDialog: function(){
                this.activityTrackingAttendeeDialog = false;
            },

            fn_attendedCount: function () {
                this.TotalAttendedCount = 0;
                (this.activityDetailObj.Attendees || []).forEach(attendee => {
                    if (attendee.IsAttendanceMarked === true) {
                        this.TotalAttendedCount++;
                    }
                    (attendee.SubAttendees || []).forEach(subAttendee => {
                        if (subAttendee.IsAttendanceMarked === true) {
                            this.TotalAttendedCount++;
                        }
                    });
                });
            },

            fn_attendeesCount: function(){
                this.TotalAttendeesCount = 0;
                (this.activityDetailObj.Attendees || []).forEach(attendee => {
                    this.TotalAttendeesCount++;
                    (attendee.SubAttendees || []).forEach(subAttendee => {
                        console.log(subAttendee);
                        this.TotalAttendeesCount++;
                    });
                });
            },

            fn_scrollToView: function () {
                setTimeout(() => {
                    var ele = document.getElementById('activityblock');
                    if (ele != null) {
                        ele.scrollIntoView();
                    }
                }, 100);
            },

            fn_getGuestAge: function(item){
                return item.AttendeeName + ' (' + item.Age + (item.Age === 1 ? ' year)' : ' years)');
            },

            formatDietaryRequirements(personalConsiderations) {
                const { HalalCertified, KosherCertified, IndianVegeterian, AllergyDetail } = personalConsiderations;

                // Start with the base dietary requirements
                let requirements = [];

                // Add additional requirements
                if (HalalCertified !== null) {
                    requirements.push(HalalCertified === true ? 'Required Halal Certified Meals: Yes' : 'Halal Certified Meals: No');
                }

                if (IndianVegeterian !== null) {
                    requirements.push(IndianVegeterian === true ? 'Indian Vegetarian Meals: Yes' : 'Indian Vegetarian Meals: No');
                }

                if (KosherCertified !== null) {
                    requirements.push(KosherCertified === true ? 'Required Kosher Certified Meals: Yes' : 'Kosher Certified Meals: No');
                }

                if (AllergyDetail) {
                    requirements.push('Allergy: ' + AllergyDetail);
                }

                // Filter out any null or undefined values (just in case)
                return requirements.filter(item => item);
            },

            fn_getActivityTitle: function(activity){
                if(activity.TrackerType == 2)
                    return activity.ActivityName + ' (Non-Hosted Activity)'
                else
                    return activity.ActivityName
            }


            // fn_updateScanDetails: function(requestId){

            //     if(!navigator.onLine){
            //         this.$root.fn_showToast("Internet not available");
            //         return;
            //     }

            //     this.loading = true;
            //     this.error = false;

            //     var headersObj = {
            //         headers: { 
            //             'MobileAppKey': '0193c477-8f0b-4a50-970a-e3be5cc6bf99',
            //             // 'ViewName':'Winner',
            //             // 'TripSiteID':7,
            //             'SiteType':3,
            //             // 'Authorization': 'bearer '+token,
            //             }
            //         };
                    

            //     this.$root.fn_showToast('Verifying details...');

            //     axios.get(this.$root.baseUrl + "/api/mobileapp/admin/gifting/"+requestId+"/qrcodescan", headersObj, {timeout:30000})
            //     .then(response => {
            //         this.loading = false;
            //         console.log(response);
            //         this.$root.fn_showToast("Details updated");
            //     }).catch(e => {
            //         this.loading = false;
            //         this.error = true;
            //         console.log(e);
            //         this.$root.fn_showToast("Something went wrong. Try again");
            //     })
            //     // }
            //     // })
            // }
        },
        mounted() {
            this.$root.fn_pageLoadComplete();
            this.fn_checkUpdate();
            this.$root.fn_getPersonalInfo();
            // this.fn_getActivityList();
        },

        computed: {
            ActivityBlocksList() {
                for (var i in this.activityList) {
                    if (this.activityList[i].ActivityID == this.itemObj.ActivityID) {
                        return this.activityList[i].ActivityBlocks;
                    }
                } 
                
                return [];
            },

            ActivityName() {
                for (var i in this.activityList) {
                    if (this.activityList[i].ActivityID == this.itemObj.ActivityID) {
                        return this.activityList[i].ActivityName;
                    }
                } 
                
                return null;
            },

            ActivityType() {
                for (var i in this.activityList) {
                    if (this.activityList[i].ActivityID == this.itemObj.ActivityID) {
                        return this.activityList[i].TrackerType;
                    }
                } 
                
                return null;
            },

            ActivityBlockDetail() {
                for (var i in this.ActivityBlocksList) {
                    if (this.ActivityBlocksList[i].ActivityBlockID == this.itemObj.ActivityBlockID) {
                        return this.ActivityBlocksList[i];
                    }
                } 
                
                return {};
            }

        }
    }
</script>

<style>
.attendee_checkbox .v-label{
    font-size: 14pt;
}

.list_header{
    min-height: 32px !important;
    height: 42px;
}

.list_header .v-list-item__icon{
    margin-top: 6px !important;
}
</style>